<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New IAO Severity
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined dismissible icon="mdi-thermometer-alert" prominent type="info">
          <h3>BAO Severity Configuration</h3>
          <ul>
            <li>Severities add another dimension to UnStruct's IAO categorization, enhancing the platform's capability to prioritize and manage IAOs effectively.</li>
            <li>This added layer not only aids in the quick assessment of an IAO's impact but also introduces a degree of flexibility in handling different types of IAOs.</li>
            <li>By defining severity levels, organizations can tailor their response strategies to match the urgency and potential impact of each IAO, ensuring that resources are allocated efficiently and that high-severity IAOs receive immediate attention.</li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field v-model="q" append-icon="search" label="Search" single-line hide-details clearable />
          </v-card-title>
          <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
            v-for="item in items"
            :key="item.id"
          >
            <v-card
              class="mb-5"
              outlined
            >
              <v-card-title class="justify-space-between">
                <div>{{ item.name }}</div>
                <v-spacer></v-spacer>
                <v-chip
                  v-if="item.default"
                  color="secondary"
                  dark
                  class="mr-2"
                >
                  <v-icon left>mdi-star</v-icon>
                  Default
                </v-chip>
                <v-chip
                  v-if="item.enabled"
                  color="success"
                  dark
                >
                  <v-icon left>mdi-lightbulb-on-outline</v-icon>
                  Enabled
                </v-chip>
              </v-card-title>
              <v-card-actions class="justify-end">
                <v-btn
                  icon
                  color="primary"
                  @click="createEditShow(item)"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import NewEditSheet from "@/incident/severity/NewEditSheet.vue"
import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"

export default {
  name: "IncidentSeverityTable",

  components: {
    NewEditSheet,
    SettingsBreadcrumbs,
  },

  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Description", value: "description", sortable: false },
        { text: "Default", value: "default", sortable: true },
        { text: "Enabled", value: "enabled", sortable: true },
        { text: "View Order", value: "view_order", sortable: true },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
    }
  },

  computed: {
    ...mapFields("incident_severity", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query", "params"]),
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAll()

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("incident_severity", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>
